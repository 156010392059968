.initial-page-animated {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}

.initial-page-fadeInLeft {
  /* -webkit-animation-name: pq-fadeInLeft; */
  animation-name: initial-page-anim-fadeInLeft;
}

.initial-page-fadeInRight {
  /* -webkit-animation-name: pq-fadeInRight; */
  animation-name: initial-page-anim-fadeInRight;
}

.feedback-relative {
  position: absolute;
}

.initial-page-test {
  display: block;
}




@media screen and (max-width: 1400px){
  .do-not-show-1400px {
    display: none;
  }
}

@media screen and (max-width: 1200px){
  .do-not-show-1200px {
    display: none;
  }
}

@media screen and (max-width: 1000px){
  .do-not-show-1000px {
    display: none;
  }
}

@media screen and (max-width: 800px){
  .do-not-show-800px {
    display: none;
  }

  .form-group {
    margin-bottom: 1.2rem!important;
  }
}

@media screen and (max-width: 300px), screen and (max-height: 600px) {
  /* .display-none-for-300-or-less {
    display: none;
  } */
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}

/* Animations */
@keyframes initial-page-anim-fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes initial-page-anim-fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes initial-page-anim-fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes initial-page-anim-fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
