body {
  background-color: white !important;
  font-family: "Rubik", sans-serif !important;
}

.App {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
}

ul {
  list-style-position: inside;
}
.drop_shadow {
  box-shadow: 0 0 4px #ccc;
}
.logo_container img {
  text-align: left;
  max-width: 250px;
  margin-left: 300px;
  margin-right: 20px;
  z-index: 12000;
}
.middle > * {
  vertical-align: middle;
}
.stage1-container-store {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prequalify-container {
  padding-top: 100px;
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  justify-content: center;
}

.action-container {
  width: 600px;
}

.pre-qualify-container-self,
.stage1-container-self,
.stage2-container-self,
.stage3-container-self,
.stage4-container-self,
.stage5-container-self {
  display: flex;
  align-items: center;
  justify-content: center;
}

#partner-logo-small {
  display: none;
  width: 160px;
}
.buttonsSmall {
  display: none !important;
}
.quoteContainer {
  padding: 10px;
  margin: 10px;
  /* background-color: #F6F6F6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}
.step-label {
  font-size: 16px !important;
}
.step-label-completed {
  font-size: 16px !important;
  color: rgb(132, 222, 185) !important;
}

.step-label-warning {
  font-size: 16px !important;
  color: rgb(222, 132, 132) !important;
}

.application-container {
  display: flex;
  min-width: 350px;
}

.right-sidebar {
  max-width: 800px;
  padding-left: 0px;
  overflow: hidden;
}

.left-sidebar {
  max-width: 500px;
  min-width: 450px;
  max-height: 800px;
  background-color: #fff;
  border-radius: 5px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.07),
    0px 15px 35px rgba(50, 50, 93, 0.1);
  position: relative;
  text-align: center;
}

.central-template {
  max-width: 1000px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.07),
    0px 15px 35px rgba(50, 50, 93, 0.1);
  border-radius: 15px;
  background-color: white;
  padding: 30px;
  /* margin-top: 30px; */
}

.background-green {
  position: absolute;
  width: 100%;
  left: 0%;
  right: 0.03%;
  top: -4.73%;
  bottom: 10.12%;
  z-index: -1;
}

.background-image {
  position: fixed;
  z-index: -2;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* width: 100%; */
}

.background-image-first {
  position: fixed;
  z-index: -2;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.background-completed {
  position: absolute;
  /* width:100%; */
  bottom: 0;
}

.input-filled {
  color: #32325d !important;
}

.bg-mg-blue {
  background: #01579b !important;
  width: 800px;
}

.mg-progress-bar {
  background-color: #90cc9a !important;
}

.terms-conditions-dialog {
  width: 800px;
}

.merchant-logo-stage-1 {
  position: absolute;
  width: 200px;
  top: 10px;
  left: 10px;
}

.modal-dialog {
  max-width: 700px !important;
}

.lead,
p,
.dz-message {
  font-weight: 400 !important;
}

.tool-item {
  margin-left: 20px;
  margin-right: 20px;
  width: 20px;
}

.mg-side-logo-container {
  top: -15px;
  left: 20px;
  position: absolute;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: bold;
}

.mg-side-logo {
  width: 250px;
}

.pre-qualify-protected {
  margin: 10px auto 10px auto;
  margin-bottom: 50;
}

.stage2-protected {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 20px;
}

.stage1-protected,
.stage3-protected,
.stage4-protected,
.stage5-protected {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 80px;
}

.stage3-protected {
  margin-top: 20px !important;
}

.stage1-margin {
  margin-top: 120px;
}

.stage3-margin {
  margin-top: 60px;
}

.stage3-padding {
  padding-bottom: 20px;
  padding-top: 50px;
}

.check-block {
  margin-left: 140px;
}

.check-block-general {
  margin-left: 140px;
  margin-top: 30px;
  margin-bottom: 27px;
}

.quote-block {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.grecaptcha-badge {
  z-index: 999999;
}

.rdtDay.rdtToday.rdtActive,
.rdtDay.rdtActive,
.rdtDay.rdtActive:hover,
.rdtDay.start-date,
.rdtDay.end-date,
.rdtDay.middle-date,
.rdtDay.start-date:hover,
.rdtDay.end-date:hover,
.rdtDay.middle-date:hover {
  background-color: #0776bd !important;
  color: #fff !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #01579b !important;
}

.custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #01579b !important;
  box-shadow: none;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #01579b !important;
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: #01579b !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #01579b !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #01579b !important;
}

.custom-checkbox .custom-control-input:disabled:checked::before {
  border-color: rgba(94, 114, 228, 0.5);
}

/* Media queries */

@media screen and (max-width: 1200px) {
  .check-block, .check-block-general {
    margin-left: 20px;
  }
  .check-block .lead {
    margin: 5px 10px !important;
  }
  .quote-block img {
    width: 50px !important;
    height: 50px !important;
  }
  .quote-block .col-auto {
    margin: 0px 0px 0px 15px !important;
  }
  .quote-block .row {
    align-items: start !important;
  }
}

@media screen and (max-width: 900px) {
  .pre-qualify-container-self,
  .stage1-container-self,
  .stage2-container-self,
  .stage3-container-self,
  .stage4-container-self,
  .stage5-container-self {
    display: block;
    padding: 10px;
  }

  .stage1-protected,
  .stage2-protected,
  .stage3-protected,
  .stage4-protected,
  .stage5-protected {
    font-size: 16px;
    margin: 15px 15px 0px 15px;
    position: inherit;
    bottom: 0;
  }

  .background-image {
    width: inherit;
  }

  .item-name {
    font-size: 0.9rem !important;
  }

  .hidden-for-mobile {
    display: none;
  }

  .stage1-margin {
    margin-top: 0px;
  }

  .stage3-margin {
    margin-top: 0px;
  }

  .merchant-logo-stage-1 {
    right: 20px;
    width: 150px;
    left: unset;
  }

  .mg-description-title {
    font-size: 1rem !important;
    width: 300px;
    margin: auto;
  }

  .central-template {
    border-radius: 15px;
    /* box-shadow: unset; */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 5px;
  }
  .action-container {
    width: 100%;
  }
  .tool-item {
    margin-left: 10px;
    margin-right: 10px;
    width: 15px;
  }

  .mg-side-logo-container {
    top: 10px !important;
  }

  .mg-side-logo {
    width: 170px;
  }
}

@media screen and (max-width: 500px) {
  .left-sidebar {
    height: inherit !important;
    padding-bottom: 20px;
    min-width: 390px !important;
  }
  .eligibility-button {
    margin: 10px !important;
  }
}

@media screen and (max-width: 960px) {
  .buttonsRegular {
    display: none !important;
  }
  .buttonsSmall {
    display: flex !important;
    min-height: 50px;
  }
  .quotesHeader {
    text-align: center !important;
  }
  .quoteContainer {
    flex-flow: column-reverse;
  }
  #partner-logo-small {
    display: block;
  }
  #partner-logo {
    display: none;
  }
  .address {
    max-width: 300px;
  }
  .subtitle {
    max-width: 300px;
  }
  .left-sidebar {
    min-height: 500px;
    padding: 20px;
    border-radius: 0px 110px 0px 110px;
    margin: 10px;
    min-width: 390px !important;
  }
  .prequalify-container {
    padding: 0px;
    flex-direction: column;
  }
  .right-sidebar {
    /* background-color: white;
    margin: 0px 10px 50px 10px;
    border-radius: 60px; */
    padding-left: 0;
  }
  .check-block, .check-block-general {
    margin-left: 10;
  }
  .quote-block {
    margin: 0px !important;
    padding: 10px;
  }
  .quote-block img {
    width: 100px !important;
    height: 100px !important;
  }
  .quote-block .col-auto {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 700px), screen and (max-height: 850px) {
  .application-container {
    height: inherit;
  }
  .stage1-container-self {
    height: unset;
  }
  .stage1-container-store {
    height: unset;
  }
}

@media screen and (max-width: 1150px), screen and (max-height: 600px) {
  #application-form {
    padding-top: 0px !important;
  }
  .header {
    position: inherit;
    width: inherit;
  }
  /* .right-sidebar {
      display: none;
 } */
  /* .quote-block {
    margin-top: 200px;
  } */
}

/* Animations */
.animated {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.loading-animation span {
  animation-name: blink;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
.loading-animation span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-animation span:nth-child(3) {
  animation-delay: 0.4s;
}

.uploadedFile {
  background-color: rgb(161, 223, 197) !important;
  animation: color_change_uploaded 2s;
}

.acceptedFile {
  background-color: rgb(132, 222, 185) !important;
  animation: color_change_accepted 2s;
}

.cancelledFile {
  background-color: rgb(222, 132, 132) !important;
  animation: color_change_cancelled 2s;
}

.reRequestedFile {
  background-color: rgb(216, 222, 132) !important;
  animation: color_change_rerequested 2s;
}

@-webkit-keyframes color_change_uploaded {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(161, 223, 197);
  }
}
@-moz-keyframes color_change_uploaded {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(161, 223, 197);
  }
}
@-ms-keyframes color_change_uploaded {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(161, 223, 197);
  }
}
@-o-keyframes color_change_uploaded {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(161, 223, 197);
  }
}
@keyframes color_change_uploaded {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(161, 223, 197);
  }
}

@-webkit-keyframes color_change_rerequested {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(216, 222, 132);
  }
}
@-moz-keyframes color_change_rerequested {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(216, 222, 132);
  }
}
@-ms-keyframes color_change_rerequested {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(216, 222, 132);
  }
}
@-o-keyframes color_change_rerequested {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(216, 222, 132);
  }
}
@keyframes color_change_rerequested {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(216, 222, 132);
  }
}

@-webkit-keyframes color_change_accepted {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(132, 222, 185);
  }
}
@-moz-keyframes color_change_accepted {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(132, 222, 185);
  }
}
@-ms-keyframes color_change_accepted {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(132, 222, 185);
  }
}
@-o-keyframes color_change_accepted {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(132, 222, 185);
  }
}
@keyframes color_change_accepted {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(132, 222, 185);
  }
}

@-webkit-keyframes color_change_cancelled {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(222, 132, 132);
  }
}
@-moz-keyframes color_change_cancelled {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(222, 132, 132);
  }
}
@-ms-keyframes color_change_cancelled {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(222, 132, 132);
  }
}
@-o-keyframes color_change_cancelled {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(222, 132, 132);
  }
}
@keyframes color_change_cancelled {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgb(222, 132, 132);
  }
}

.pulsate-bck {
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
}

/* ----------------------------------------------
* Generated by Animista on 2019-11-25 17:35:59
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation pulsate-bck
* ----------------------------------------
*/
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.text-focus-in {
  -webkit-animation: text-focus-in 500ms cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 500ms cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
