.background-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 10px;
}

.background-logo-inverted {
  filter: brightness(0) invert(1);
}

@media screen and (max-width: 1024px) {
  .background-footer {
    position: unset;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-height: 850px) {
  .background-footer {
    margin-top: 10px;
    position: inherit;
  }
}

/* Animations */
.background-animated {
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
}

.background-fadeInBottom {
  /* -webkit-animation-name: pq-fadeInLeft; */
  animation-name: background-anim-fadeInBottom;
}

@keyframes background-anim-fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes background-anim-fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

