.arrow_box {
  color: #3f3f3f;
  background: #f4f4f4;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent;
  border-top: 1px solid #d6d6d6;
  outline: none;
  padding: 10px 0 10px 15px;
  font-size: 18px;
  caret-color: #07a3ee;
  border-radius: 4px;
}

:root {
  --borderWidth: 4px;
  --height: 24px;
  --width: 12px;
  --borderColor: #78b13f;
}

.check {
  display: inline-block;
  margin-left: 20px;
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
}

.address-animated {
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
}

.address-fadeInBottom {
  /* -webkit-animation-name: pq-fadeInLeft; */
  animation-name: address-fadeInBottom;
}

@keyframes address-fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes address-fadeInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
