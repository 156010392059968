.partner-card-container {
    max-width: 359px;
    height: fit-content;
    max-height: 450px;
    /* border-radius: 5px; */
    /* box-shadow: 1px 1px 10px lightgrey; */
}

.partner-logo {
    height: 80px;
    display: flex;
    justify-content: center;    
}

.partner-logo img {
    max-width: 100%;
    max-height: 100%;
}

.card-small-text {
    font-size: 0.9em !important;
}

.card-large-text {
    font-size: 2em;
}

.card-medium-text {
    font-size: 1.1em;
}

.very-close-to-bottom-sibling {
    margin-bottom: -5px;
}

.partner-card-button {
    width: 85%;
    background-color: #3446da !important;
    color: white !important;
    border-radius: 100px !important;
}


/* Detail View */

.partner-card-detail-container {
    overflow-y: auto;
    max-width: 359px;
    height: 100vh;
    /* border-radius: 5px;
    box-shadow: 1px 1px 10px lightgrey; */
}

.filler-space {
    border-bottom: 0.1px dashed lightgrey;
    margin-bottom: 5px;
}

.button-container {
    max-width: 359px;
}

.button-container button {
    width: 90%;
}

.bold-text {
    font-weight: 600;
}

/* Pending View */
.pending-data-container p {
    color: #283947;
    font-size: 1em;
    line-height: 1.15 !important;
}

.large-font {
    font-size: 1.5em !important;
    font-weight: 400;
}

/* Loading View */

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.loading-animation-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}