.show {
    display: block!important;
}

/* Animations */

.animated-fast {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
  }
  
  .animated {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .fadeInBottom {
    -webkit-animation-name: fadeInBottom;
    animation-name: fadeInBottom;
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeInBottom {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @-webkit-keyframes fadeInBottom {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  
  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }
  
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @-webkit-keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }
  