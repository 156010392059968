.stepper-form {
  min-width: 800px;
  min-height: 190px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Opera and Firefox */
}

.stepper-item {
  margin: 20px;
}

.stepper-indent {
  width: 100%;
  /* margin-top: 40px; */
  /* margin-bottom: 40px; */
}

.stepper-image {
  width: 500px;
}

.stepper-wrapper {
  display: flex;
  max-width: 800px;
}

.form-control:focus {
  border-color: #b0b0b0 !important;
}

.pcanotification {
  display: none !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a:hover {
  /* color: #0776bd !important; */
}

@media screen and (max-width: 1200px) {
  .stepper-form {
    min-width: 600px;
  }
  .stepper-indent {
    min-width: 600px;
  }
}

@media screen and (max-width: 800px) {
  .stepper-indent {
    min-width: 400px;
  }
  .stepper-form {
    min-width: 400px;
  }
  .stepper-image {
    width: 450px;
  }
}

@media screen and (max-width: 500px) {
  .stepper-form {
    min-width: 100%;
  }
  .stepper-indent {
    min-width: 100%;
    /* min-height: 65vh; */
    margin-top: unset;
    margin-bottom: unset;
  }

  .stepper-image {
    width: 320px;
  }

  .stepper-item {
    margin: unset;
  }

  .App-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
    /* font-size: calc(10px + 2vmin); */
  }

  /* .central-template { */
    /* height: calc(100vh - 10px); */
  /* } */
}

@media screen and (max-width: 320px) {
  .stepper-image {
    width: 250px;
  }
}

.step-transition-enter {
  opacity: 0;
  /* transform: translate(100%); */
}
.step-transition-enter.step-transition-enter-active {
  opacity: 1;
  /* transform: translate(0%); */
  transition-property: opacity;
  transition-duration: 0.1s;
  transition-delay: 0.1s;
  /* transition-property: opacity, transform;
  transition-duration:1s, 0.2s; */
  transition-timing-function: linear;
}
.step-transition-leave {
  opacity: 1;
  transform: translate(-100%);
}
.step-transition-leave.step-transition-leave-active {
  opacity: 0;
  /* transform: translate(-200%); */
  transition-property: opacity;
  transition-duration: 0.1s;
  /* transition-property: opacity, transform;
  transition-duration:0.5s, 0.2s; */
  transition-timing-function: linear;
}

.step-transition-reverse-enter {
  opacity: 0;
  /* transform: translate(-100%); */
}
.step-transition-reverse-enter.step-transition-reverse-enter-active {
  opacity: 1;
  /* transform: translate(0%); */
  transition-property: opacity;
  transition-duration: 0.1s;
  transition-delay: 0.1s;
  /* transition-property: opacity, transform;
  transition-duration:1s, 0.2s; */
  transition-timing-function: linear;
}
.step-transition-reverse-leave {
  opacity: 1;
  transform: translate(-100%);
}
.step-transition-reverse-leave.step-transition-reverse-leave-active {
  opacity: 0;
  /* transform: translate(0%); */
  transition-property: opacity;
  transition-duration: 0.1s;
  /* transition-property: opacity, transform;
  transition-duration:0.5s, 0.2s; */
  transition-timing-function: linear;
}

/* Toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}


input:checked + .slider {
  background-color: #b0b0b0;
}

input:focus + .slider {
  box-shadow: 0 0 1px#b0b0b0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.terms-link:hover {
  text-decoration: underline;
}

.pulsate-bck {
  -webkit-animation: pulsate-bck 1s ease-in-out infinite both;
  animation: pulsate-bck 1s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-4 12:47:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.slide-in-blurred-right {
  -webkit-animation: slide-in-blurred-right 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: slide-in-blurred-right 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-4 18:33:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.slide-out-blurred-left {
  -webkit-animation: slide-out-blurred-left 0.45s
    cubic-bezier(0.755, 0.05, 0.855, 0.06) both;
  animation: slide-out-blurred-left 0.45s cubic-bezier(0.755, 0.05, 0.855, 0.06)
    both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-4 18:34:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-left {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-left {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-8 12:24:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */

 .handle-shake {
	-webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}


 @-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
  100% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
  100% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

