.buttons-block {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (min-width: 900px) {
    .iframeWrapper {
        width: 800px!important;
    }
}