.title {
  margin: 0 0 0.75rem;
  font-size: 1.5rem;
}

.subtitle {
  margin: 0 0 2.5rem;
}

.autocomplete {
  display: inline-block;
  width: 100%;
  position: relative;
}

.input {
  padding: 0.9rem 1.15rem;
  width: 400px;
  border: 1px solid #bbb;
  border-radius: 8px;
  outline: none;
  line-height: 1.2;
}

.inputNoBottomRadius {
  border-bottom-left-radius: 0!important;
  border-bottom-right-radius: 0!important;
}

.listBox {
  position: absolute;
  margin: 0;
  padding: 0;
  /* max-height: 60%; */
  overflow-y: auto;
  border: 1px solid #b0b0b0;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  list-style-type: none;
  text-align: left;
  z-index: 9;
  background-color: white;
  width: 100%;
}

.listItem {
  padding: 0.75rem 1.15rem;
  cursor: pointer;
}

.listItemDarken {
  background: #eee;
}

.subText {
  color: #8c8c8c;
}

.logo {
  padding: 0.75rem 1.15rem;
  text-align: right;
}

@media (min-width: 576px) {
  .title {
    font-size: 2rem;
  }
  .input {
    width: 20rem;
  }
}


